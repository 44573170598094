<template>
  <el-container class="account t-white">
    <div>
      <el-aside class="hidden-xs-only" width="317px" v-if="$storage.has('token')">
        <div class="box d-flex flex-column ai-center margin30-0">
          <div class="d-flex flex-column ai-center active_box">
            <div class="name flex-center align-center">
              <span class="bold">{{ infos.nickname }} #{{ infos.userid }}</span>
              <img src="../assets/account/edit.png" style="width: 12px; height: 12px; margin-left: 5px" class="hover"
                @click="$refs.resetNameDom.nameShow = true" />
            </div>
            <!-- 未激活  0-未激活，1 - BGG 激活；2- BRP激活 -->
            <img v-if="infos.vip_active_status == 0" src="../assets/account/vip.png" alt="" class="m-t-5"
              style="width:120px">
            <!-- 已激活 -->
            <img v-else src="../assets/account/vip2.png" alt="" class="m-t-5" style="width:120px">
            <!-- <div class="btn_group px-15 w-100 m-t-5 d-flex ai-center jc-between bold t-center"
              v-if="infos.vip_active_status == 0">
              <div class="btn rp_btn d-flex flex-column jc-center cursor" @click="onBeginAvtive('brp')">
                <p>{{sets.brp_vip_active_amount}}MDV</p>
                <p>{{ $t("account.Activate") }}</p>
              </div>
              <div class="btn bgg_btn d-flex flex-column jc-center cursor" @click="onBeginAvtive('bgg')">
                <p>{{sets.mds_vip_active_amount}}META</p>
                <p>{{ $t("account.Activate") }}</p>
              </div>
            </div> -->
            <!-- 激活中 -->
            <!-- <div class="Activateing_btn m-t-5 text-center" style="background: #999">
            {{ $t("account.Activateing") }}
          </div> -->
            <!-- 领取 -->
            <!-- <div v-if="infos.vip_active_status != 0" class="receive_btn t-center m-t-5 cursor"
            @click="onReceive" v-loading="reciveLoading"
            element-loading-background="rgba(0, 0, 0, 0.3)">{{ $t("account.receive") }}</div> -->
            <!-- 未激活 -->
            <!-- <p v-if="infos.vip_active_status == 0" class="m-t-10 tip">{{ $t("account.activetips") }}
            </p> -->
            <!-- 已激活 -->
            <!-- <p v-else class="m-t-20 tip2">{{ $t("account.hasActivateTip") }}</p> -->
          </div>
          <div class="w-100">
            <div class="navItem flex align-center cursor" :class="{ active: $route.path == '/accountAccount' }"
              @click="jump('/accountAccount')">
              <img src="../assets/account/account.png" style="width: 20px; height: 20px; margin-right: 10px" />
              <span>{{ $t("menu.account") }}</span>
            </div>
            <div class="navItem flex align-center cursor" :class="{ active: $route.path == '/activity' }"
              @click="jump('/activity')">
              <img src="../assets/account/activity.png" style="width: 20px; height: 20px; margin-right: 10px" />
              <span>{{ $t("menu.activity") }}</span>
            </div>
            <!-- <div class="navItem flex align-center cursor" :class="{ active: $route.path == '/legion' }"
              @click="jump('/legion')">
              <img src="../assets/account/legion.png" style="width: 20px; height: 20px; margin-right: 10px" />
              <span>{{ $t("menu.legion") }}</span>
            </div> -->
            <div class="navItem flex align-center cursor" :class="{ active: $route.path == '/shares' }"
              @click="jump('/shares')">
              <img src="../assets/account/shares.png" style="width: 20px; height: 20px; margin-right: 10px" />
              <span>{{ $t("menu.share") }}</span>
            </div>
            <!-- 设置 -->
            <div v-if="infos.email" class="navItem flex align-center cursor"
              :class="{ active: $route.path == '/setting' }" @click="jump('/setting')">
              <img src="../assets/account/setting.png" style="width: 20px; height: 20px; margin-right: 10px" />
              <span>{{ $t("menu.setting") }}</span>
            </div>
            <div v-else class="navItem flex align-center cursor notallow" @click="settingFun">
              <img src="../assets/account/setting.png" style="width: 20px; height: 20px; margin-right: 10px" />
              <span>{{ $t("menu.setting") }}</span>
            </div>
          </div>
          <!-- <div class="email flex-center align-center">
            <span>{{ infos.email }}</span>
          </div>-->
        </div>
        <div class="logout w-100 d-flex ai-center jc-center p-t-15 hover" @click="logout()">
          <img src="../assets/account/shutdown.png" alt="" width="24px" />
          <span class="t-color6 m-l-5">{{ $t("menu.logout") }}</span>
        </div>
      </el-aside>
      <el-main :class="$storage.has('token')?'marginleft':''">
        <router-view></router-view>
      </el-main>
      <ResetName ref="resetNameDom"></ResetName>
    </div>
    <el-dialog :visible.sync="avtiveVisible" center :title="$t('account.Activateaccount')" :modal="false">
      <p class="t-center lh-3 t-color2"
        v-html="$t('account.activetip_p',{type:clickType=='brp'?sets.brp_vip_active_amount+'MDV':sets.mds_vip_active_amount+'META'})">
      </p>
      <div class="d-flex ai-center jc-around my-20">
        <el-button class="btnpopup can_btn" @click="avtiveVisible = false">
          {{ $t("messageBox.cancel") }}</el-button>
        <el-button class="btnpopup con_btn" @click="onActive">
          {{ $t("messageBox.confirm") }}</el-button>

      </div>
    </el-dialog>
    <el-dialog :visible.sync="bggVisible" center :title="$t('messageBox.mdvTip')" :modal="false">
      <p class="t-color2 t-center">{{ $t("messageBox.nobalance") }}</p>
      <div class="d-flex ai-center jc-around my-20">
        <el-button class="btnpopup can_btn" @click="bggVisible = false">
          {{ $t("messageBox.cancel") }}</el-button>
        <el-button class="btnpopup con_btn" :loading="bggDepositLoading" @click="desBRP">
          {{ $t("messageBox.mdvCofirm") }}</el-button>
      </div>
    </el-dialog>
    <!-- 输入资金密码 -->
    <el-dialog :visible.sync="setFundBox" :show-close="true" :center="true" :modal="false" :title="$t('fund.fund')">
      <el-input autocomplete="off" :placeholder="$t('fund.p_pass')" v-model="paypass" type="password">
        <!-- 是否有支付密码0-无，1-有 -->
        <template #append v-if="infos.has_paypass == 0">
          <span @click="$router.push('/setting');setFundBox = false" style="color:#37B497"
            class="fs-sm hover">{{$t("fund.savefund")}}</span>
        </template>
      </el-input>
      <el-button style="width: 100%" type="primary" @click="activeShow()" :loading="isfundLoading">
        {{ $t("messageBox.confirm") }}</el-button>
    </el-dialog>
    <bot />
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import { logout, business } from "@/api/common/index.api";
import ResetName from "@/components/ResetName.vue";
import BigNumber from 'bignumber.js';
export default {
  inject: ["reload"],
  data () {
    return {
      name: this.$route.matched[0].name,
      avtiveVisible: false,
      email: "",
      qrStr: "",
      lang: {
        cn: "ZH-CN",
        tw: "ZH-TW",
        en: "EN-US",
      },
      clickType: "",
      loading: false,
      setFundBox: false,
      paypass: "",
      isfundLoading: false,
      reciveLoading: false,
      bggVisible: false,
      bggDepositLoading: false
    };
  },
  components: { ResetName },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
      isLogin: (state) => state.isLogin,
      serverid: (state) => state.serverid,
      sets: (state) => state.sets,
    }),
  },
  watch: {
    avtiveVisible: {
      handler (n, o) {
        if (n) {
          this.loading = false
        }
      }
    }
  },
  created () {
    if (this.$storage.has("token")) this.$store.dispatch("Get_websiteList");
  },
  mounted () {
    this.getInfos();
  },
  methods: {
    jump (path) {
      if (this.$route.path == path) return;
      this.$router.push(path);
    },
    getInfos () {
      this.$store.dispatch("Get_infos").then((res) => {
        this.email = res.email;
      });
    },
    qrFun () {
      if (!this.email) return this.$message(this.$t("tip.noEmail"));
      this.$axios
        .post(this.$api.getToken, {}, { Serverid: this.$store.state.serverid })
        .then((res) => {
          this.qrStr = "token=" + res.data.token + "&Language=" + this.lang[this._i18n.locale];
          this.dialogVisible = true;
        });
    },
    //退出登录
    logout () {
      this.$msgbox.alert(
        this.$t("messageBox.logoutTip"),
        this.$t("messageBox.tip"),
        {
          confirmButtonText: this.$t("messageBox.confirm"),
          center: true,
          callback: (action) => {
            if (action === "confirm") {
              logout({}, { Serverid: this.$store.state.serverid });
              setTimeout(() => {
                this.$storage.remove("token");
                this.$store.commit("Set_serverid", "");
                this.$store.commit("Set_isLogin", false);
                this.$router.push("/login");
                this.$storage.clear();
                this.reload();
              }, 500);
            }
          },
        }
      );
    },
    settingFun () {
      this.$msgbox.alert(
        this.$t("messageBox.emailTip"),
        this.$t("messageBox.tip"),
        { confirmButtonText: this.$t("messageBox.close"), center: true }
      );
    },
    activeShow () {
      let that = this;
      if (!this.paypass) return this.$message(this.$t("fund.p_pass"));
      this.isfundLoading = true;
      this.$axios.post(this.clickType == 'bgg' ? this.$api.bggactive : this.$api.brpactive, {
        password: this.paypass
      }, { Serverid: this.$store.state.serverid }).then(res => {
        if (res.code == 200) {
          this.isfundLoading = false;
          this.setFundBox = false;
          this.$message(this.$t('activate.ok'))
          this.$store.dispatch("Get_infos");
        }
      }).catch(err => {
        this.isfundLoading = false;
      })
    },
    // 充值brp
    desBRP () {
      let contract = new web3.eth.Contract(
        this.$store.state.activeBrpAbi,
        this.sets.presale_coin_contract_address
      );
      this.bggDepositLoading = true
      let amount = '0x' + BigNumber(this.sets.brp_vip_active_amount).toString(16).replace(/\.\w+$/, '')
      console.log(amount);
      contract.methods.transfer(this.sets.active_presale_coin_to_address, amount)
        .send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE }, (error, result) => {
          business({ txhash: result }, { Serverid: this.$store.state.serverid })
          if (error) {
            this.$message.error(error.message);
            this.bggDepositLoading = false;
            this.bggVisible = false
          } else if (result) {
            setTimeout(() => {
              this.$msgbox.alert(`<img alt="tip" src="${require('../assets/public/tip.png')}" style="height:62px;margin-bottom:20px;"><p>${this.$t('messageBox.activetip')}</p>`, "", {
                confirmButtonText: this.$t("messageBox.confirm"),
                center: true,
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                  if (action === "confirm") {
                    this.bggDepositLoading = false;
                    this.bggVisible = false
                    setTimeout(() => {
                      this.pageFun(1)
                      this.$store.dispatch("Get_infos");
                    }, 500);
                  }
                },
              });
            }, 500);
          }
        })
    },
    onBeginAvtive (type) {
      if (!this.infos.email) {
        return this.$msgbox.alert(
          this.$t("messageBox.emailTip"),
          this.$t("messageBox.tip"),
          { confirmButtonText: this.$t("messageBox.close"), center: true }
        );
      }
      // if (type == 'bgg' && Number(this.infos.coins) < Number(this.sets.mds_vip_active_amount)) return this.$message(this.$t('trans.err_able'));
      // 先判断brp余额是否大于0 ，小于=0 是 拉起钱包充值，大于0 是调用激活vip接口
      if (type == 'brp' && Number(this.infos.pre_coins) <= 0) return this.bggVisible = true
      this.clickType = type
      this.avtiveVisible = true
    },
    // 激活确认
    async onActive () {
      this.avtiveVisible = false
      this.setFundBox = true;
    },
    // 领取
    onReceive () {

    }
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  min-height: calc(100vh);
  position: relative;
  background: url('~@/assets/public/bg.jpg') no-repeat;
  background-attachment: fixed;
  background-size: 1920px 100%;
  background-position: center center;
  flex-direction: column;
  z-index: 2;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(33, 34, 47, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.bule {
  color: #56a1ff;
}
.btnpopup {
  min-width: 126px;
  height: 38px;
  border-radius: 4px;
}
.can_btn {
  border: 1px solid #37B497 !important;
  background: transparent !important;
  color: #37B497 !important;
}
.el-aside {
  position: relative;
  border-right: 1px solid #999999;
  height: calc(100vh - 60px);
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 8999;
  .box {
    width: 226px;
    height: 564px;
    border-radius: 4px;
    background: url('../assets/account/aside.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 0 20px 0;
    .active_box {
      width: 100%;
      min-height: 150px;
      padding-top: 20px;
    }
    .name {
      font-size: 16px;
    }
    .btn_group {
      .btn {
        width: 48%;
        height: 38px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 1.2;
      }
      .rp_btn {
        background: linear-gradient(172deg, #29F19C, #02A1F9);
      }
      .bgg_btn {
        background: #f49314;
      }
    }
    .Activateing_btn {
      width: 90%;
      height: 38px;
      line-height: 38px;
      border-radius: 4px;
      font-size: 12px;
      border: 1px solid #fff;
      background: transparent !important;
      color: #fff;
    }
    .receive_btn {
      width: 90%;
      height: 38px;
      line-height: 38px;
      border-radius: 4px;
      font-size: 12px;
      background: #317F6E;
    }
    .tip {
      font-size: 12px;
      color: #ffde00;
    }
    .tip2 {
      font-size: 12px;
      color: #09ff54;
    }
    .email {
      font-size: 12px;
      font-weight: 400;
      color: #1c7dd2;
      line-height: 24px;
      opacity: 0.8;
    }
    .qrBox {
      width: 110px;
      height: 36px;
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-size: 14px;
    }
    .line {
      width: 100%;
      height: 1px;
      background: #fff;
    }
    .navItem {
      width: 100%;
      height: 48px;
      font-size: 14px;
      padding-left: 18px;
      &:hover {
        background: #317F6E;
      }
    }
    .active {
      background: #317F6E;
    }
  }
  .first {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .logout {
    width: 216px;
    border-top: 1px solid #999999;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.marginleft {
  margin-left: 337px;
}
@media screen and (max-width: 768px) {
  .el-main {
    margin-left: 0;
  }
}
</style>